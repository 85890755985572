/* App.css */

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  overflow-x: hidden;
  background-color: #f9f9f9;
}

/* Header Styles */
.App-header {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 0.8rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-logo {
  height: 30px;
}

.App-nav a {
  color: #333;
  margin: 0 20px;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s;
}

.App-nav a:hover {
  color: #1abc9c;
}

/* Hero Section */
.Hero-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 100px 5% 60px;
  background: linear-gradient(135deg, #2C3E50 0%, #1ABC9C 100%);
  color: #fff;
}

.Hero-text {
  flex: 1 1 50%;
}

.Hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.Hero-text p {
  font-size: 20px;
  margin-bottom: 30px;
  line-height: 1.6;
}

.Hero-button {
  background-color: #1abc9c;
  color: #fff;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.Hero-button:hover {
  background-color: #16a085;
}

.Hero-image {
  flex: 1 1 50%;
  text-align: center;
}

.Hero-image img {
  max-width: 100%;
  height: auto;
}

/* About Us Section */
.AboutUs-section {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 5%;
}

.AboutUs-content {
  flex: 1 1 50%;
}

.AboutUs-content h2 {
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.AboutUs-content p {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.8;
  color: #555;
}

.AboutUs-image {
  flex: 1 1 50%;
  text-align: center;
}

.AboutUs-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Services Section */
.Services-section {
  padding: 80px 5%;
  background-color: #fff;
  text-align: center;
}

.Services-section h2 {
  font-size: 36px;
  color: #1abc9c;
  margin-bottom: 40px;
}

.Services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.Service-card {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  flex: 1 1 calc(30% - 80px);
  max-width: calc(30% - 80px);
  padding: 40px 25px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0,0,0,0.1);
}

.Service-icon {
  font-size: 50px;
  color: #1abc9c;
  margin-bottom: 25px;
}

.Service-card h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.Service-card p {
  font-size: 16px;
  color: #666;
  line-height: 1.8;
}

/* Platforms Section */
.Platforms-section {
  padding: 80px 5%;
  background-color: #f9f9f9;
}

.Platforms-section h2 {
  font-size: 36px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 60px;
}

.Platform {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 80px;
}

.Platform.reverse {
  flex-direction: row-reverse;
}

.Platform-content {
  flex: 1 1 50%;
  padding: 0 30px;
}

.Platform-content h3 {
  font-size: 32px;
  color: #1abc9c;
  margin-bottom: 25px;
}

.Platform-content p {
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.8;
  color: #555;
}

.Platform-button {
  background-color: #2c3e50;
  color: #fff;
  padding: 15px 35px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.Platform-button:hover {
  background-color: #1abc9c;
}

.Platform-image {
  flex: 1 1 50%;
  text-align: center;
  padding: 20px;
}

.Platform-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Lists for Platform Benefits */
.Platform-benefits {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.Platform-benefits li {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.5;
  color: #555;
}

.Platform-benefits li strong {
  color: #2c3e50;
}

/* Testimonials Section */
.Testimonials-section {
  padding: 80px 5%;
  background-color: #fff;
  text-align: center;
}

.Testimonials-section h2 {
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 60px;
}

.Testimonials-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.Testimonial-card {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  flex: 1 1 calc(30% - 80px);
  max-width: calc(30% - 80px);
  padding: 40px 25px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s;
}

.Testimonial-card:hover {
  transform: translateY(-5px);
}

.Testimonial-card p {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
  line-height: 1.8;
  color: #555;
}

.Testimonial-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #2c3e50;
}

/* Contact Section */
.Contact-section {
  padding: 80px 5%;
  text-align: center;
  background-color: #f0f0ff;
}

.Contact-section h2 {
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 30px;
}

.Contact-section p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #555;
}

.Contact-button {
  background-color: #1abc9c;
  color: #fff;
  padding: 15px 35px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.Contact-button:hover {
  background-color: #16a085;
}

/* Footer Styles */
.App-footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 30px 5%;
  text-align: center;
}

.Footer-links {
  margin-top: 15px;
}

.Footer-links a {
  color: #1abc9c;
  margin: 0 15px;
  text-decoration: none;
}

.Footer-links a:hover {
  text-decoration: underline;
}

.Footer-links svg {
  font-size: 24px;
  margin: 0 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .Service-card,
  .Testimonial-card {
    flex: 1 1 calc(45% - 80px);
    max-width: calc(45% - 80px);
  }
}

@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    padding: 1rem;
  }

  .App-nav {
    margin-top: 1rem;
  }

  .Hero-section {
    padding-top: 130px;
  }

  .Hero-section,
  .AboutUs-section,
  .Platform {
    flex-direction: column;
  }

  .Platform.reverse {
    flex-direction: column;
  }

  .Hero-text,
  .AboutUs-content,
  .Platform-content,
  .Hero-image,
  .AboutUs-image,
  .Platform-image {
    flex: 1 1 100%;
    text-align: center;
  }

  .Platform-content,
  .Platform-image {
    padding: 20px 0;
  }

  .Services-grid,
  .Testimonials-grid {
    flex-direction: column;
  }

  .Service-card,
  .Testimonial-card {
    max-width: 100%;
  }
}
